export const FILE_UPLOAD_TO_SERVER_SAGA = 'FILE_UPLOAD_TO_SERVER_SAGA';
export const FILE_UPLOAD_TO_SERVER_START = 'FILE_UPLOAD_TO_SERVER_START';
export const FILE_UPLOAD_TO_SERVER_SUCCESS = 'FILE_UPLOAD_TO_SERVER_SUCCESS';
export const FILE_UPLOAD_TO_SERVER_FAIL = 'FILE_UPLOAD_TO_SERVER_FAIL';

export const FILE_DOWNLOAD_FROM_SERVER_SAGA = 'FILE_DOWNLOAD_FROM_SERVER_SAGA';
export const FILE_DOWNLOAD_FROM_SERVER_START = 'FILE_DOWNLOAD_FROM_SERVER_START';
export const FILE_DOWNLOAD_FROM_SERVER_SUCCESS = 'FILE_DOWNLOAD_FROM_SERVER_SUCCESS';
export const FILE_DOWNLOAD_FROM_SERVER_FAIL = 'FILE_DOWNLOAD_FROM_SERVER_FAIL';

export const GET_FILE_LIST_SAGA = 'GET_FILE_LIST_SAGA';
export const GET_FILE_LIST_START = 'GET_FILE_LIST_START';
export const GET_FILE_LIST_SUCCESS = 'GET_FILE_LIST_SUCCESS';
export const GET_FILE_LIST_FAIL = 'GET_FILE_LIST_FAIL';

export const CLEAR_DATA_REQUEST_SAGA = 'CLEAR_DATA_REQUEST_SAGA';
export const CLEAR_DATA_REQUEST_START = 'CLEAR_DATA_REQUEST_START';
export const CLEAR_DATA_REQUEST_SUCCESS = 'CLEAR_DATA_REQUEST_SUCCESS';
export const CLEAR_DATA_REQUEST_FAIL = 'CLEAR_DATA_REQUEST_FAIL';

export const SELF_TEST_REPORT_SAGA = 'SELF_TEST_REPORT_SAGA';
export const SELF_TEST_REPORT_START = 'SELF_TEST_REPORT_START';
export const SELF_TEST_REPORT_SUCCESS = 'SELF_TEST_REPORT_SUCCESS';
export const SELF_TEST_REPORT_FAIL = 'SELF_TEST_REPORT_FAIL';

export const SET_PROGRESS = 'SET_PROGRESS';
export const SHOW_DOWNLOAD_LINK = 'SHOW_DOWNLOAD_LINK';
export const REMOVE_LIST_FILE = 'REMOVE_LIST_FILE';
