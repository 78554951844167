import { LOGO_SM_PNG } from '../../../assets/images';
import './Loader.scss';

const Loader = props => {
  const { isFullLoader, width, marginTop, className = '', style = {}, showLogo = false } = props;
  return (
    <div
      className={`custom-loader text-center ${width || 'w-100'} ${
        isFullLoader && 'full-loader'
      } ${className} `}
      style={marginTop ? { marginTop, ...style } : { ...style }}
    >
      {/* // <img src={LOADING_GIF} alt="" width="30" className="loader-gif-img" /> */}
      <div className="custom-loader__loader logo-loader">
        {showLogo && (
          <img
            className="custom-loader__loader__image"
            src={LOGO_SM_PNG}
            alt="Logo"
            width={13}
            height={20}
          />
        )}

        <div className="chase">
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
          <div className="chase-dot" />
        </div>
      </div>
    </div>
  );
};
export default Loader;
