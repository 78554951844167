import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { logout } from '../../store/actions';
import './ErrorBoundary.scss';

class ErrorBoundary extends React.Component {
  constructor() {
    super();
    this.state = { hasError: false };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  async componentDidCatch(error, info) {
    const { ipcRenderer } = window.commChannel;

    const appVersion = await ipcRenderer.invoke('get-app-version');

    let userId = null;

    if (this.props.userData) {
      userId = this.props.userData._id;
    }

    const payload = {
      error: error.toString(),
      stackTrace: info.componentStack,
      userId,
      browserInfo: navigator.userAgent,
      appState: {
        currentRoute: window.location.href,
      },
      appVersion,
      reactAppVersion: process.env.REACT_APP_VERSION,
    };

    axios.post(`${process.env.REACT_APP_END_POINT_URL_DEV}/report-crash`, {
      ...payload,
    });
  }

  logoutHandler = () => {
    this.props.logout();
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        //   <h1>Something went wrong. Our team has been notified.</h1>
        <div className="error-container">
          <div className="error-content">
            <div className="error-image">
              <img src={require('../../assets/images/logo.png')} alt="Logo" width={100} />
              <p className="login-form__logo__name brand-name">eFileSecure</p>
            </div>
            <h1>Oops!</h1>
            <p>
              Something went wrong on our end. <br /> Don&apos;t worry, our team has been notified
              and we&apos;ll fix it shortly.
            </p>
            <a href="/">Return Home</a>
            <button className="link-button mt-3" type="button" onClick={this.logoutHandler}>
              Logout
            </button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

const mapStateToProps = state => ({
  userData: state.auth.userData,
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
