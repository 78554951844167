/* eslint-disable no-unused-vars */
import { put, call } from 'redux-saga/effects';
import {
  loginSuccess,
  loginFail,
  loginStart,
  signupSuccess,
  signupFail,
  signupStart,
  logout,
  logoutStart,
  logoutSuccess,
  resetApp,
  getUserDetailStart,
  getUserDetailSuccess,
  getUserDetailSaga as getUserDetailSagaAction,
  getCurrentUserDetailSuccess,
  getUserDetailFail,
  getCurrentUserDetailFail,
  editUserDetailStart,
  resendOTPStart,
  resendOTPSuccess,
  resendOTPFail,
  verifyOTPStart,
  verifyOTPSuccess,
  verifyOTPFail,
  editUserDetailSuccess,
  editUserDetailFail,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFail,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFail,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFail,
} from '../../actions';
import { errorHandler, setCookie } from '../../../utils';

export function* loginSaga(action) {
  yield put(loginStart());
  const { requestBody, setIsSubmitted } = action.payload;
  const { ipcRenderer } = window.commChannel;
  const uuid = yield ipcRenderer
    .invoke('get-uuid')
    .then(responseData => responseData)
    .catch(error => null);
  requestBody.uuid = uuid;
  yield errorHandler({
    endpoint: `/login`,
    successHandler: yield function* (response) {
      if ((response.data.person && response.data.person.token) || response.data.token) {
        yield call([localStorage, 'setItem'], 'authToken', response.data.token);
        yield call([localStorage, 'setItem'], 'userid', response.data._id);
      }
      if (requestBody.rememberMe) {
        yield call([localStorage, 'setItem'], 'email', requestBody.email);
      } else {
        yield call([localStorage, 'removeItem'], 'email');
      }
      // const data = { ...response.data };
      setIsSubmitted(false);
      yield put(
        loginSuccess({
          token: response.data.token,
          data: response.data,
          // ...response.data,
          // ...response.data.userData,
        }),
      );
      // window.location.reload();
    },
    failHandler: yield function* (response) {
      yield put(loginFail(response));
      setIsSubmitted(false);
    },
    failHandlerType: 'CUSTOM',
    payload: requestBody,
    apiType: 'post',
  });
  // yield call([localStorage, 'setItem'], 'authToken', true);
  // yield call([localStorage, 'setItem'], 'userid', true);
}

export function* signupSaga(action) {
  yield put(signupStart());
  const { data, setIsSubmitted } = action.payload;
  const { ipcRenderer } = window.commChannel;
  const uuid = yield ipcRenderer
    .invoke('get-uuid')
    .then(responseData => responseData)
    .catch(error => null);
  data.uuid = uuid;
  yield errorHandler({
    endpoint: `/signup`,
    successHandler: yield function* (response) {
      // yield put(signupSuccess(response.data));
      if (response.data.token) {
        yield call([localStorage, 'setItem'], 'authToken', response.data.token);
        yield call([localStorage, 'setItem'], 'userid', response.data._id);
      }
      // const responseData = response.data;
      setIsSubmitted(false);
      yield put(
        loginSuccess({
          // token: response.data.token,
          // ...responseData,
          ...response.data,
          // ...response.data.userData,
        }),
      );
    },
    failHandler: yield function* (response) {
      yield put(signupFail(response));
      setIsSubmitted(false);
    },
    failHandlerType: 'CUSTOM',
    payload: data,
    apiType: 'post',
  });
}

export function* forgotPasswordSaga(action) {
  yield put(forgotPasswordStart());
  const { requestBody, setIsSubmitted, callback } = action.payload;
  yield errorHandler({
    endpoint: `/forgot-password`,
    successHandler: yield function* (response) {
      yield put(forgotPasswordSuccess(response.data));
      if (callback) {
        callback(requestBody.email);
      }
    },
    failHandler: forgotPasswordFail,
    payload: requestBody,
    apiType: 'post',
  });
  if (setIsSubmitted) {
    setIsSubmitted(false);
  }
}

export function* resetPasswordSaga(action) {
  yield put(resetPasswordStart());
  const { requestBody, setIsSubmitted, callback } = action.payload;
  yield errorHandler({
    endpoint: `/reset-password`,
    successHandler: yield function* (response) {
      yield put(resetPasswordSuccess(response.data));
      if (callback) {
        callback();
      }
    },
    failHandler: resetPasswordFail,
    payload: requestBody,
    apiType: 'post',
  });
  if (setIsSubmitted) {
    setIsSubmitted(false);
  }
}

export function* changePasswordSaga(action) {
  yield put(changePasswordStart());
  const { data, setIsSubmitted, successCallback = null } = action.payload;
  yield errorHandler({
    endpoint: `/change-password`,
    successHandler: yield function* (response) {
      yield put(changePasswordSuccess(response.data));
      if (successCallback) {
        successCallback();
      }
    },
    failHandler: changePasswordFail,
    payload: data,
    apiType: 'put',
  });
  if (setIsSubmitted) {
    setIsSubmitted(false);
  }
}

export function* resendOTPSaga(action) {
  yield put(resendOTPStart());
  const { email, callback = null } = action.payload;
  yield errorHandler({
    endpoint: `/resend-otp?email=${email}`,
    successHandler: yield function* (response) {
      yield put(resendOTPSuccess(response.data));
      callback(true);
    },
    failHandler: yield function* (response) {
      yield put(resendOTPFail(response));
      callback(false);
    },
    failHandlerType: 'CUSTOM',
    apiType: 'post',
  });
  return false;
}

export function* verifyOTPSaga(action) {
  yield put(verifyOTPStart());
  const { email, otp, is2FA = false, isForgotPassword = false, callback } = action.payload;
  yield errorHandler({
    // eslint-disable-next-line max-len
    endpoint: `/verify-otp?email=${email}&otp=${otp}&is2FA=${is2FA}&isForgotPassword=${isForgotPassword}`,
    successHandler: yield function* (response) {
      setCookie(`2fa_${email}`, 'verified', 24);
      yield put(verifyOTPSuccess(response.data));
      if (callback) {
        callback({ email, otp });
      }
      if (!isForgotPassword) {
        yield put(getUserDetailSagaAction());
      }
    },
    failHandler: verifyOTPFail,
    apiType: 'post',
  });
}

export function* logoutSaga(action) {
  yield put(logoutStart());
  const email = yield call([localStorage, 'getItem'], 'email');
  yield call([localStorage, 'clear']);
  if (email) {
    yield call([localStorage, 'setItem'], 'email', email);
  }
  yield put(resetApp());
  yield put(logoutSuccess());
}

// eslint-disable-next-line no-unused-vars
export function* authenticationValidatorSaga(action) {
  yield put(loginStart());
  const token = yield localStorage.getItem('authToken');
  const responseData = yield JSON.parse(localStorage.getItem('data'));
  if (!token) {
    yield put(logout({ fromAuth: true }));
  } else {
    // yield put(
    //   getUserDetailSagaAction({
    //     data: { token },
    //     isCurrentUser: true,
    //   }),
    // );
    yield put(loginSuccess({ token }));
  }
}

export function* getUserDetailSaga(action) {
  yield put(getUserDetailStart());
  yield errorHandler({
    endpoint: `/users`,
    successHandler: yield function* (response) {
      yield put(getUserDetailSuccess(response.data));
    },
    failHandler: getUserDetailFail,
    apiType: 'get',
  });
}

export function* editUserDetailSaga(action) {
  yield put(editUserDetailStart());
  const { data, setIsSubmitted, successCallback = null } = action.payload;
  yield errorHandler({
    endpoint: `/edit-profile`,
    successHandler: yield function* (response) {
      yield put(editUserDetailSuccess());
      yield put(getUserDetailSagaAction());
      if (successCallback) {
        successCallback();
      }
    },
    failHandler: editUserDetailFail,
    apiType: 'put',
    payload: data,
  });
  if (setIsSubmitted) {
    setIsSubmitted(false);
  }
}
