import * as actionLabels from '../../actionLabels';

export const fileUploadToServerSaga = payload => ({
  type: actionLabels.FILE_UPLOAD_TO_SERVER_SAGA,
  payload,
});

export const fileUploadToServerStart = () => ({
  type: actionLabels.FILE_UPLOAD_TO_SERVER_START,
});

export const fileUploadToServerSuccess = payload => ({
  type: actionLabels.FILE_UPLOAD_TO_SERVER_SUCCESS,
  payload,
});

export const fileUploadToServerFail = payload => ({
  type: actionLabels.FILE_UPLOAD_TO_SERVER_FAIL,
  payload,
});

export const fileDownloadFromServerSaga = payload => ({
  type: actionLabels.FILE_DOWNLOAD_FROM_SERVER_SAGA,
  payload,
});

export const fileDownloadFromServerStart = () => ({
  type: actionLabels.FILE_DOWNLOAD_FROM_SERVER_START,
});

export const fileDownloadFromServerSuccess = payload => ({
  type: actionLabels.FILE_DOWNLOAD_FROM_SERVER_SUCCESS,
  payload,
});

export const fileDownloadFromServerFail = payload => ({
  type: actionLabels.FILE_DOWNLOAD_FROM_SERVER_FAIL,
  payload,
});

export const getFileListSaga = payload => ({
  type: actionLabels.GET_FILE_LIST_SAGA,
  payload,
});

export const getFileListStart = () => ({
  type: actionLabels.GET_FILE_LIST_START,
});

export const getFileListSuccess = payload => ({
  type: actionLabels.GET_FILE_LIST_SUCCESS,
  payload,
});

export const getFileListFail = payload => ({
  type: actionLabels.GET_FILE_LIST_FAIL,
  payload,
});

export const clearDataRequestSaga = payload => ({
  type: actionLabels.CLEAR_DATA_REQUEST_SAGA,
  payload,
});

export const clearDataRequestStart = () => ({
  type: actionLabels.CLEAR_DATA_REQUEST_START,
});

export const clearDataRequestSuccess = payload => ({
  type: actionLabels.CLEAR_DATA_REQUEST_SUCCESS,
  payload,
});

export const clearDataRequestFail = payload => ({
  type: actionLabels.CLEAR_DATA_REQUEST_FAIL,
  payload,
});

export const selfTestReportSaga = payload => ({
  type: actionLabels.SELF_TEST_REPORT_SAGA,
  payload,
});

export const selfTestReportStart = () => ({
  type: actionLabels.SELF_TEST_REPORT_START,
});

export const selfTestReportSuccess = payload => ({
  type: actionLabels.SELF_TEST_REPORT_SUCCESS,
  payload,
});

export const selfTestReportFail = payload => ({
  type: actionLabels.SELF_TEST_REPORT_FAIL,
  payload,
});

export const setProgress = payload => ({
  type: actionLabels.SET_PROGRESS,
  payload,
});

export const showDownloadLink = payload => ({
  type: actionLabels.SHOW_DOWNLOAD_LINK,
  payload,
});

export const removeListFile = payload => ({
  type: actionLabels.REMOVE_LIST_FILE,
  payload,
});
