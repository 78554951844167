import { all, takeLatest } from 'redux-saga/effects';
import * as actionLabels from '../actionLabels';
import {
  loginSaga,
  logoutSaga,
  authenticationValidatorSaga,
  signupSaga,
  getUserDetailSaga,
  editUserDetailSaga,
  resendOTPSaga,
  verifyOTPSaga,
  changePasswordSaga,
  forgotPasswordSaga,
  resetPasswordSaga,
} from './auth/auth';
import {
  clearDataRequestSaga,
  fileDownloadFromServerSaga,
  fileUploadToServerSaga,
  getFileListSaga,
  selfTestReportSaga,
} from './fileControl/fileControl';

export function* watchAuthentication() {
  yield all([
    takeLatest(actionLabels.LOGIN_SAGA, loginSaga),
    takeLatest(actionLabels.SIGNUP_SAGA, signupSaga),
    takeLatest(actionLabels.FORGOT_PASSWORD_SAGA, forgotPasswordSaga),
    takeLatest(actionLabels.RESET_PASSWORD_SAGA, resetPasswordSaga),
    takeLatest(actionLabels.CHANGE_PASSWORD_SAGA, changePasswordSaga),
    takeLatest(actionLabels.RESEND_OTP_SAGA, resendOTPSaga),
    takeLatest(actionLabels.VERIFY_OTP_SAGA, verifyOTPSaga),
    takeLatest(actionLabels.AUTHENTICATION_VALIDATOR, authenticationValidatorSaga),
    takeLatest(actionLabels.LOGOUT_SAGA, logoutSaga),
    takeLatest(actionLabels.GET_USER_DETAIL_SAGA, getUserDetailSaga),
    takeLatest(actionLabels.EDIT_USER_DETAIL_SAGA, editUserDetailSaga),
  ]);
}

export function* watchFileControl() {
  yield all([takeLatest(actionLabels.FILE_UPLOAD_TO_SERVER_SAGA, fileUploadToServerSaga)]);
  yield all([takeLatest(actionLabels.FILE_DOWNLOAD_FROM_SERVER_SAGA, fileDownloadFromServerSaga)]);
  yield all([takeLatest(actionLabels.GET_FILE_LIST_SAGA, getFileListSaga)]);
  yield all([takeLatest(actionLabels.CLEAR_DATA_REQUEST_SAGA, clearDataRequestSaga)]);
  yield all([takeLatest(actionLabels.SELF_TEST_REPORT_SAGA, selfTestReportSaga)]);
}
